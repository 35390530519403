.container {
  display: flex;
  align-items: center;
  height: 30px;
}

.link {
  position: relative;
  margin-left: 7px;
}

.link:before {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  width: 24px;
  height: 24px;
  border-radius: 5px;
}

.link:hover:before {
  background-color: #d7efff;
}

.link--moderation:before {
  top: -2px;
  left: -2px;
  width: 24px;
  height: 29px;
}

