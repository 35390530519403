.loading {
  position: relative;
  height: 100%;
}

.root {
  height: 100%;
}

.header {
  display: flex;
  justify-content: flex-end;
  padding: 10px 20px;
}

.month-picker {
  width: 200px;
}

.iframe-container {
  height: calc(100% - 60px);
}

.iframe {
  display: block;
}
