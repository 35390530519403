.body {
  display: flex;
  height: calc(100% - 70px);
}

.content {
  width: 100%;
}

.content--nav-opened {
  width: calc(100% - 210px);
}
