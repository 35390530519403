.root {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 210px;
  height: 50px;
}

.root--disabled {
  pointer-events: none;
  opacity: 0.2;
}

.text {
  white-space: nowrap;
  line-height: 1;
}
