.root {
  position: relative;
  z-index: 1;
  box-shadow: 0 1px 4px -1px rgb(0 0 0 / 15%);
  background-color: white;
}

.root--dark-theme {
  background-color: #283142;
}
