.country-flag-container {
  display: inline-block;
  width: 16px;
  min-width: 16px;
}

.update-time-icon {
  font-size: 21px;
  cursor: pointer;
}
