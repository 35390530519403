.root {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  box-shadow: 0 1px 4px -1px rgb(0 0 0 / 15%);
  background-color: white;
}

.root--dark-theme {
  background-color: #283142;
}

.burger {
  margin-left: 20px;
}

.header-title {
  flex-grow: 1;
  display: flex;
  align-items: center;
  margin: 0 0 0 20px;
}

.right-section {
  display: flex;
  align-items: center;
}

.settings-icon {
  margin: 0 20px;
}

.settings-container {
  padding-top: 30px;
}
